import React from "react";
import Untrusive from "untrusive";
import { lighten } from "polished";
import { Button, Input, RotateLoader, styled, Tooltip } from "@lib/components";
import { api } from "../api";
import { cc } from "../config";
import { runInThisContext } from "vm";
import MultiSelect from "@khanacademy/react-multi-select";
import TransferRestaurant from "./changereseller/transferRestaurant";

// For new resellers:
// 1. Add them to this list.
// 2. In the root of the project: yarn deploy:accounttes

// const valid_support_email_list = [
// 	"support@cloudwaitress.com",
// 	"support@placebag.co",
// 	"no-reply@deliverish.com.au",
// 	"support@deliverlocal.co",
// 	"noreply@email-ticket.com",
// 	"support@takeaway.com.au",
// 	"keerati@foodonlineorders.com",
// 	"support@fiberexdine.com",
// 	"support@tsonlineorders.com",
// 	"support@grubshout.menu",
// 	"info@myfoodorder.com.au",
// 	"support@local-orders.com",
// 	"support@yoom.com.au",
// 	"foodoncloud@email-ticket.com",
// 	"sales@onlineorderingking.com.au",
// 	"eat@primetechnologies.com.sg",
// 	"grolocal@email-ticket.com",
// 	"orders@orderfeeds.com",
// 	"skytakeout@email-ticket.com",
// 	"support@munchhouses.co.uk"
// ];

function customerIDCheck(customerID: string): boolean {
	// if (customerID.includes(" ")) {
	// 	alert("The customer ID cannot have spaces")
	// 	return false
	// } else if (customerID.includes("@")) {
	// 	alert("The customer ID cannot have '@'")
	// 	return false
	// } else {
	return true;
	// }
}

function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

const loader = new Untrusive({
	bgColor: lighten(0.2, cc.color),
	barColor: cc.color,
});

function copyToClipboard(str: string) {
	const el = document.createElement("textarea");
	el.value = str;
	el.setAttribute("readonly", "");
	el.style.position = "absolute";
	el.style.left = "-9999px";
	document.body.appendChild(el);
	el.select();
	const range = document.createRange();
	range.selectNodeContents(el);
	const selection = window.getSelection();
	selection!.removeAllRanges();
	selection!.addRange(range);
	el.setSelectionRange(0, 999999);
	document.execCommand("copy");
	document.body.removeChild(el);
}

function loginUser(_id: string, copy: boolean) {
	let winRef: null | Window = null;
	if (!copy) {
		winRef = window.open();
	}
	loader.start();
	(async () => {
		try {
			const { url } = await api.loginUser({ _id });
			// const baseUrl = cc.production ? reseller : "http://localhost:3001";
			// const url = `${baseUrl}?authToken=${token}`;
			if (copy) copyToClipboard(url);
			else if (winRef) {
				winRef.location.href = url;
				winRef.focus();
			}
		} catch (e) {
			console.log(e);
			alert("Something went wrong");
		} finally {
			loader.stop();
		}
	})();
}

function compareReseller(a: any, b: any) {
	const resA = a.reseller_id.toUpperCase();
	const resB = b.reseller_id.toUpperCase();
	//
	let comparison = 0;
	if (resA > resB) {
		comparison = 1;
	} else if (resA < resB) {
		comparison = -1;
	}
	return comparison;
}

function compareName(a: any, b: any) {
	const resA = a.name.toUpperCase();
	const resB = b.name.toUpperCase();
	//
	let comparison = 0;
	if (resA > resB) {
		comparison = 1;
	} else if (resA < resB) {
		comparison = -1;
	}
	return comparison;
}

interface Props {
	email: string;
	toggleMode: any;
	billing_user: boolean;
}

const generateBillingStatus = (flag: string): string => {
	let billing = flag === "true" ? "Active" : "Inactive";
	return billing;
};

export class ViewAccounts extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props);
		//
		let billing_user = true;
		// if (valid_support_email_list.indexOf(props.email) > -1) {
		// 	billing_user = true;
		// }
		//
		this.state = {
			email: props.email,
			mode: "N",
			billing_user: billing_user,
		};
	}
	toggleMode = (newMode: string) => {
		this.setState({
			mode: newMode, //this.state.mode === "N" ? "M" : "N"
		});
	};
	render() {
		if (this.state.mode === "N") {
			return (
				<NormalView
					toggleMode={this.toggleMode}
					email={this.state.email}
					billing_user={this.state.billing_user}
				/>
			);
		} else if (this.state.mode === "M") {
			return (
				<MasterView
					toggleMode={this.toggleMode}
					email={this.state.email}
					billing_user={this.state.billing_user}
				/>
			);
		} else if (this.state.mode === "C") {
			return (
				<MenuCopyView
					toggleMode={this.toggleMode}
					email={this.state.email}
					billing_user={this.state.billing_user}
				/>
			);
		} else if (this.state.mode === "T") {
			return (
				<TransferRestaurant toggleMode={this.toggleMode} />
			);
		}
	}
}

const yearOptions = [
	{
		label: "2020",
		value: "2020",
	},
	{
		label: "2021",
		value: "2021",
	},
];

export class NormalView extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props);
		const today = new Date();
		this.state = {
			email: props.email,
			filter: "",
			resellerId: "",
			modal: false,
			modalReseller: false,
			modalTest: false,
			invoiceData: [],
			billing: "false",
			billing_original: "false",
			base: 0,
			original_base: 0,
			stripe: "",
			stripe_original: "",
			menu_api: false,
			menu_api_original: false,
			currency: "",
			currency_original: "",
			masterFeatures: false,
			data: [],
			accounts: [],
			totalUsers: 0,
			totalData: 0,
			billingStatus: "",
			currentRestaurant: "",
			currentBase: 0,
			currentMenuAPI: "false",
			currentFeeType: "P",
			currentGoogleApiKey: "",
			currentFeeAmount: 0,
			currentBaseOriginal: 0,
			currentMenuAPIOriginal: "false",
			currentFeeTypeOriginal: "P",
			currentGoogleApiKeyOriginal: "",
			currentFeeAmountOriginal: 0,
			currentOrgID: "",
			currentStripeID: "",
			currentStripeIDOriginal: "",
			//
			currentServiceFeesDefault: false,
			currentDeliveryFeesDefault: false,
			currentPickupTipsDefault: false,
			currentDeliveryTipsDefault: false,
			currentDineinTipsDefault: false,
			//
			currentServiceFeesDefaultOriginal: false,
			currentDeliveryFeesDefaultOriginal: false,
			currentPickupTipsDefaultOriginal: false,
			currentDeliveryTipsDefaultOriginal: false,
			currentDineinTipsDefaultOriginal: false,
			//
			commissionFeeDefaultOriginal: 0,
			//
			currentServiceFees: false,
			currentDeliveryFees: false,
			currentPickupTips: false,
			currentDeliveryTips: false,
			currentDineinTips: false,
			//
			currentServiceFeesOriginal: false,
			currentDeliveryFeesOriginal: false,
			currentPickupTipsOriginal: false,
			currentDeliveryTipsOriginal: false,
			currentDineinTipsOriginal: false,
			//
			// restaurantBilling_serviceFees: false,
			// restaurantBillingDeliveryFees: false,
			// restaurantBillingPickupTips: false,
			// restaurantBillingDeliveryTips: false,
			// restaurantBillingDineinTips: false,
			// //
			// restaurantBilling_serviceFeesOriginal: false,
			// restaurantBillingDeliveryFeesOriginal: false,
			// restaurantBillingPickupTipsOriginal: false,
			// restaurantBillingDeliveryTipsOriginal: false,
			// restaurantBillingDineinTipsOriginal: false,
			//
			invoiceLoading: false,
			testLoading: false,
			upcoming_total: 0,
			year: today.getFullYear().toString(),
			month: NumberToMonth(today.getMonth()),
			billing_user: props.billing_user,
			searchKey: '',
		};
	}

	limit: number = 10;
	skip: number = 0;
	disabledNextButton: boolean = false;
	loadRestaurants = (limit: number, skip: number, searchKey: string) => {
		api.accounts(limit, skip, searchKey).then((res) => {
			if (res.accounts.length == 0) {
				var temp: any = this.state.searchKey
				this.setState({ searchKey: '' });
				alert("No Accounts Found For " + temp);
				return;
			}
			if (res.accounts.length < limit) {
				this.disabledNextButton = true;
			} else {
				this.disabledNextButton = false;
			}
			let data = res.accounts.sort(compareReseller);
			let masterFeatures = this.state.email === "support@cloudwaitress.com";
			let billingStatus;
			let billing;
			let menu_api = false;
			if (res.reseller) {
				billingStatus = generateBillingStatus(
					res.reseller.billing.restaurant_billing_flag
				);
				billing = res.reseller.billing.restaurant_billing_flag;
				menu_api =
					res.reseller && res.reseller.menu_api ? res.reseller.menu_api : false;
			}
			this.setState({
				accounts: res.accounts,
				data: data,
				menu_api: menu_api,
				resellerId: res.reseller_id,
				totalUsers: data.length,
				totalRestaurants: data.reduce((a, u) => a + u.restaurants.length, 0),
				billingStatus: billingStatus,
				masterFeatures: masterFeatures,
				billing: billing,
				billing_original: billing,
				base: res.reseller
					? res.reseller.billing.restaurant_billing_base_fee_default
					: "",
				base_original: res.reseller
					? res.reseller.billing.restaurant_billing_base_fee_default
					: "",
				fee_amount: res.reseller
					? res.reseller.billing.restaurant_billing_fee_amount_default
					: "",
				fee_amount_original: res.reseller
					? res.reseller.billing.restaurant_billing_fee_amount_default
					: "",
				fee_type: res.reseller
					? res.reseller.billing.restaurant_billing_fee_type_default
					: "",
				fee_type_original: res.reseller
					? res.reseller.billing.restaurant_billing_fee_type_default
					: "",
				stripe: res.reseller
					? res.reseller.billing.restaurant_billing_stripe
					: "",
				stripe_original: res.reseller
					? res.reseller.billing.restaurant_billing_stripe
					: "",
				currency: res.reseller
					? res.reseller.billing.restaurant_billing_currency
					: "",
				currency_original: res.reseller
					? res.reseller.billing.restaurant_billing_currency
					: "",
				//
				currentServiceFeesDefault: res.reseller
					? res.reseller.billing.restaurant_billing_service_fees_default
					: false,
				currentDeliveryFeesDefault: res.reseller
					? res.reseller.billing.restaurant_billing_delivery_fees_default
					: false,
				currentPickupTipsDefault: res.reseller
					? res.reseller.billing.restaurant_billing_pickup_tips_default
					: false,
				currentDeliveryTipsDefault: res.reseller
					? res.reseller.billing.restaurant_billing_delivery_tips_default
					: false,
				currentDineinTipsDefault: res.reseller
					? res.reseller.billing.restaurant_billing_dinein_tips_default
					: false,
				//
				currentServiceFeesDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_service_fees_default
					: false,
				currentDeliveryFeesDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_delivery_fees_default
					: false,
				currentPickupTipsDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_pickup_tips_default
					: false,
				currentDeliveryTipsDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_delivery_tips_default
					: false,
				currentDineinTipsDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_dinein_tips_default
					: false,
				commissionFeeDefaultOriginal: res.reseller
					? res.reseller.billing.reseller_commission_fee
					: 0,
				currentGoogleApiKeyOriginal: res.reseller
					? res.reseller.billing.reseller_billing_google_api_key
					: "",
				currentGoogleApiKey: res.reseller
					? res.reseller.billing.reseller_billing_google_api_key
					: "",
			});
		});
	};
	componentDidMount = () => {
		api.accounts(10, 0, this.state.searchKey).then((res) => {
			if (res.accounts.length == 0) {
				var temp: any = this.state.searchKey
				this.setState({ searchKey: '' });
				alert("No Accounts Found For " + temp);
				return;
			}
			let data = res.accounts.sort(compareReseller);
			let masterFeatures = this.state.email === "support@cloudwaitress.com";
			let billingStatus;
			let billing;
			let menu_api = false;
			if (res.reseller) {
				billingStatus = generateBillingStatus(
					res.reseller.billing.restaurant_billing_flag
				);
				billing = res.reseller.billing.restaurant_billing_flag;
				menu_api =
					res.reseller && res.reseller.menu_api ? res.reseller.menu_api : false;
			}
			this.setState({
				accounts: res.accounts,
				data: data,
				menu_api: menu_api,
				resellerId: res.reseller_id,
				totalUsers: data.length,
				totalRestaurants: data.reduce((a, u) => a + u.restaurants.length, 0),
				billingStatus: billingStatus,
				masterFeatures: masterFeatures,
				billing: billing,
				billing_original: billing,
				base: res.reseller
					? res.reseller.billing.restaurant_billing_base_fee_default
					: "",
				base_original: res.reseller
					? res.reseller.billing.restaurant_billing_base_fee_default
					: "",
				fee_amount: res.reseller
					? res.reseller.billing.restaurant_billing_fee_amount_default
					: "",
				fee_amount_original: res.reseller
					? res.reseller.billing.restaurant_billing_fee_amount_default
					: "",
				fee_type: res.reseller
					? res.reseller.billing.restaurant_billing_fee_type_default
					: "",
				fee_type_original: res.reseller
					? res.reseller.billing.restaurant_billing_fee_type_default
					: "",
				stripe: res.reseller
					? res.reseller.billing.restaurant_billing_stripe
					: "",
				stripe_original: res.reseller
					? res.reseller.billing.restaurant_billing_stripe
					: "",
				currency: res.reseller
					? res.reseller.billing.restaurant_billing_currency
					: "",
				currency_original: res.reseller
					? res.reseller.billing.restaurant_billing_currency
					: "",
				//
				currentServiceFeesDefault: res.reseller
					? res.reseller.billing.restaurant_billing_service_fees_default
					: false,
				currentDeliveryFeesDefault: res.reseller
					? res.reseller.billing.restaurant_billing_delivery_fees_default
					: false,
				currentPickupTipsDefault: res.reseller
					? res.reseller.billing.restaurant_billing_pickup_tips_default
					: false,
				currentDeliveryTipsDefault: res.reseller
					? res.reseller.billing.restaurant_billing_delivery_tips_default
					: false,
				currentDineinTipsDefault: res.reseller
					? res.reseller.billing.restaurant_billing_dinein_tips_default
					: false,
				//
				currentServiceFeesDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_service_fees_default
					: false,
				currentDeliveryFeesDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_delivery_fees_default
					: false,
				currentPickupTipsDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_pickup_tips_default
					: false,
				currentDeliveryTipsDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_delivery_tips_default
					: false,
				currentDineinTipsDefaultOriginal: res.reseller
					? res.reseller.billing.restaurant_billing_dinein_tips_default
					: false,
				commissionFeeDefaultOriginal: res.reseller
					? res.reseller.billing.reseller_commission_fee
					: 0,
				currentGoogleApiKeyOriginal: res.reseller
					? res.reseller.billing.reseller_billing_google_api_key
					: "",
				currentGoogleApiKey: res.reseller
					? res.reseller.billing.reseller_billing_google_api_key
					: "",
			});
		});
	};
	filterChanged = () => {
		let data = this.state.accounts;
		if (this.state.filter !== "") {
			data = this.state.accounts.filter((u: any) => {
				if (this.state.filter !== "Active") {
					if (u.email.toLowerCase().indexOf(this.state.filter) !== -1) {
						return true;
					}
					if (
						this.state.masterFeatures &&
						u.reseller_id.toLowerCase().indexOf(this.state.filter) !== -1
					) {
						return true;
					}
				}
				for (const r of u.restaurants) {
					if (
						this.state.filter !== "Active" &&
						r.name.toLowerCase().indexOf(this.state.filter) !== -1
					) {
						return true;
					}
					if (
						this.state.filter === "Active" &&
						(r.billing.base !== 0 || r.billing.fee_amount !== 0)
					) {
						return true;
					}
				}
				return false;
			});
		}
		this.setState({
			data: data,
		});
	};
	toggleModal = () => {
		if (!this.state.modal) {
			const body = document.querySelector("body");
			// @ts-ignore
			body.classList.add("modal-active");
		} else {
			const body = document.querySelector("body");
			// @ts-ignore
			body.classList.remove("modal-active");
		}
		if (this.state.modal === false) {
			this.setState({
				modal: !this.state.modal,
				currentBase: this.state.currentBaseOriginal,
				currentFeeType: this.state.currentFeeTypeOriginal,
				currentFeeAmount: this.state.currentFeeAmountOriginal,
				currentStripeID: this.state.currentStripeIDOriginal,
				currentMenuAPI: this.state.currentMenuAPIOriginal,
				currentServiceFees: this.state.currentServiceFeesOriginal,
				currentDeliveryFees: this.state.currentDeliveryFeesOriginal,
				currentPickupTips: this.state.currentPickupTipsOriginal,
				currentDeliveryTips: this.state.currentDeliveryTipsOriginal,
				currentDineinTips: this.state.currentDineinTipsOriginal,
			});
		} else {
			this.setState({
				modal: !this.state.modal,
			});
		}
	};
	toggleModalReseller = () => {
		if (!this.state.modalReseller) {
			const body = document.querySelector("body");
			// @ts-ignore
			body.classList.add("modal-active");
		} else {
			const body = document.querySelector("body");
			// @ts-ignore
			body.classList.remove("modal-active");
		}
		this.setState({
			modalReseller: !this.state.modalReseller,
			billing: this.state.billing_original,
			stripe: this.state.stripe_original,
			currency: this.state.currency_original,
			base: this.state.base_original,
			fee_amount: this.state.fee_amount_original,
			fee_type: this.state.fee_type_original,
			currentServiceFeesDefault: this.state.currentServiceFeesDefaultOriginal,
			currentDeliveryFeesDefault: this.state.currentDeliveryFeesDefaultOriginal,
			currentPickupTipsDefault: this.state.currentPickupTipsDefaultOriginal,
			currentDeliveryTipsDefault: this.state.currentDeliveryTipsDefaultOriginal,
			currentDineinTipsDefault: this.state.currentDineinTipsDefaultOriginal,
			commissionFeeDefault: this.state.commissionFeeDefaultOriginal,
			currentGoogleApiKey: this.state.currentGoogleApiKey,
		});
	};
	toggleModalTest = () => {
		if (!this.state.modalTest) {
			const body = document.querySelector("body");
			// @ts-ignore
			body.classList.add("modal-active");
		} else {
			const body = document.querySelector("body");
			// @ts-ignore
			body.classList.remove("modal-active");
		}
		this.setState(
			{
				modalTest: !this.state.modalTest,
				invoiceLoading: true,
			},
			() => {
				api
					.getInvoiceData({
						period: this.state.month + "-" + this.state.year,
					})
					.then((res) => {
						let upcoming_total = res.reduce(
							(total: number, invoice: any) =>
								total + invoice.base + invoice.fees,
							0
						);
						this.setState({
							invoiceLoading: false,
							invoiceData: res,
							upcoming_total: upcoming_total,
						});
					});
			}
		);
	};
	getUpcomingInvoiceData = () => {
		this.setState(
			{
				invoiceLoading: true,
			},
			() => {
				api
					.getInvoiceData({
						period: this.state.month + "-" + this.state.year,
					})
					.then((res) => {
						let upcoming_total = res.reduce(
							(total: number, invoice: any) =>
								total + invoice.base + invoice.fees,
							0
						);
						this.setState({
							invoiceLoading: false,
							invoiceData: res,
							upcoming_total: upcoming_total,
						});
					});
			}
		);
	};
	testResellerPayout = () => {
		this.setState(
			{
				testLoading: true,
			},
			async () => {
				// console.log(this.state.invoiceData)
				const currentData = this.state.invoiceData;
				for (let d of currentData) {
					// await sleep(1000);
					await api
						.postInvoiceData({
							mode: "test",
							// mode: "live",
							year: this.state.year,
							month: this.state.month,
							record: d,
						})
						.then((res) => {
							// console.log({ res })
							let oldData = this.state.invoiceData;
							let newData = oldData.map((data: any) => {
								if (d._id === data._id) {
									data.status = res[0].status;
								}
								return data;
							});
							this.setState({
								invoiceData: newData,
							});
							// return d
						})
						.catch((error) => {
							alert(error);
						});
					//
				}
				this.setState({
					testLoading: false,
				});
			}
		);
	};
	submitResellerSettings = async () => {
		let base = 0;
		if (this.state.base !== null && this.state.base !== "") {
			base = parseFloat(this.state.base);
		}
		let fee_amount = 0;
		if (this.state.fee_amount !== null && this.state.fee_amount !== "") {
			fee_amount = parseFloat(this.state.fee_amount);
		}
		const resellerSettingData = {
			billing: this.state.billing,
			base: base,
			stripe: this.state.stripe,
			currency: this.state.currency,
			fee_amount: fee_amount,
			fee_type: this.state.fee_type,
			//
			restaurant_billing_service_fees_default: this.state
				.currentServiceFeesDefault,
			restaurant_billing_delivery_fees_default: this.state
				.currentDeliveryFeesDefault,
			restaurant_billing_pickup_tips_default: this.state
				.currentPickupTipsDefault,
			restaurant_billing_delivery_tips_default: this.state
				.currentDeliveryTipsDefault,
			restaurant_billing_dinein_tips_default: this.state
				.currentDineinTipsDefault,
			//
			reseller_billing_google_api_key: this.state.currentGoogleApiKey,
		};
		if (
			base === this.state.base_original &&
			fee_amount === this.state.fee_amount_original &&
			this.state.billing === this.state.billing_original &&
			this.state.stripe === this.state.stripe_original &&
			this.state.currency === this.state.currency_original &&
			this.state.fee_type === this.state.fee_type_original &&
			this.state.currentServiceFeesDefault ===
			this.state.currentServiceFeesDefaultOriginal &&
			this.state.currentDeliveryFeesDefault ===
			this.state.currentDeliveryFeesDefaultOriginal &&
			this.state.currentPickupTipsDefault ===
			this.state.currentPickupTipsDefaultOriginal &&
			this.state.currentDeliveryTipsDefault ===
			this.state.currentDeliveryTipsDefaultOriginal &&
			this.state.currentDineinTipsDefault ===
			this.state.currentDineinTipsDefaultOriginal &&
			this.state.commissionFeeDefault ===
			this.state.commissionFeeDefaultOriginal &&
			this.state.currentGoogleApiKey === this.state.currentGoogleApiKeyOriginal
		) {
			// If the user just removed numbers, dont do anything...
			return;
		}
		api
			.updateResellerSettings(resellerSettingData)
			.then((res) => {
				if (res.message === "OK") {
					this.setState({
						modalReseller: false,
						billing_original: this.state.billing,
						base_original: base,
						base: base,
						fee_type_original: this.state.fee_type,
						fee_amount_original: fee_amount,
						stripe_original: this.state.stripe,
						currency_original: this.state.currency,
						//
						currentServiceFeesDefaultOriginal: this.state
							.currentServiceFeesDefault,
						currentDeliveryFeesDefaultOriginal: this.state
							.currentDeliveryFeesDefault,
						currentPickupTipsDefaultOriginal: this.state
							.currentPickupTipsDefault,
						currentDeliveryTipsDefaultOriginal: this.state
							.currentDeliveryTipsDefault,
						currentDineinTipsDefaultOriginal: this.state
							.currentDineinTipsDefault,
						commissionFeeDefaultOriginal: this.state
							.commissionFeeDefault,
						//
						billingStatus: generateBillingStatus(this.state.billing),
						//
						currentGoogleApiKeyOriginal: this.state.currentGoogleApiKey,
					});
					alert("The settings were updated successfully.");
				} else {
					console.log({ res });
					alert(
						"The settings were unable to be uploaded to the server. Please contact us."
					);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};
	submitRestaurantSettings = async () => {
		const customerIDCheckResult = customerIDCheck(this.state.currentStripeID);
		if (
			customerIDCheckResult === false &&
			this.state.currentMenuAPI === this.state.currentMenuAPIOriginal
		) {
			return;
		}
		let base = 0;
		if (this.state.currentBase !== null && this.state.currentBase !== "") {
			base = parseFloat(this.state.currentBase);
		}
		let fee_amount = 0;
		if (
			this.state.currentFeeAmount !== null &&
			this.state.currentFeeAmount !== ""
		) {
			fee_amount = parseFloat(this.state.currentFeeAmount);
		}
		if (
			base === this.state.currentBaseOriginal &&
			fee_amount === this.state.currentFeeAmountOriginal &&
			this.state.currentFeeType === this.state.currentFeeTypeOriginal &&
			this.state.currentMenuAPI === this.state.currentMenuAPIOriginal &&
			this.state.currentStripeID === this.state.currentStripeIDOriginal &&
			this.state.currentServiceFees === this.state.currentServiceFeesOriginal &&
			this.state.currentDeliveryFees ===
			this.state.currentDeliveryFeesOriginal &&
			this.state.currentPickupTips === this.state.currentPickupTipsOriginal &&
			this.state.currentDeliveryTips ===
			this.state.currentDeliveryTipsOriginal &&
			this.state.currentDineineTips === this.state.currentDineineTipsOriginal
		) {
			// If the user just removed numbers, dont post, but cleanup the data if needed
			const newData = this.state.data.map((d: any) => {
				d.restaurants = d.restaurants.map((r: any) => {
					if (r._id === this.state.currentRestaurant) {
						r.billing.base = base;
						r.billing.fee_amount = fee_amount;
					}
					return r;
				});
				return d;
			});
			const newAccounts = this.state.accounts.map((d: any) => {
				d.restaurants = d.restaurants.map((r: any) => {
					if (r._id === this.state.currentRestaurant) {
						r.billing.base = base;
						r.billing.fee_amount = fee_amount;
					}
					return r;
				});
				return d;
			});
			this.setState({
				data: newData,
				accounts: newAccounts,
			});
			return;
		}
		const restaurantSettingData = {
			restaurant_id: this.state.currentRestaurant,
			base: base,
			fee_type: this.state.currentFeeType,
			fee_amount: fee_amount,
			organisation_id: this.state.currentOrgID,
			billing_id_stripe: this.state.currentStripeID,
			menu_api: this.state.currentMenuAPI,
			restaurant_billing_service_fees: this.state.currentServiceFees,
			restaurant_billing_delivery_fees: this.state.currentDeliveryFees,
			restaurant_billing_pickup_tips: this.state.currentPickupTips,
			restaurant_billing_delivery_tips: this.state.currentDeliveryTips,
			restaurant_billing_dinein_tips: this.state.currentDineinTips,
		};
		api
			.updateRestaurantSettings(restaurantSettingData)
			.then((res) => {
				// console.log({res})
				if (res.message === "OK") {
					const newData = this.state.data.map((d: any) => {
						d.restaurants = d.restaurants.map((r: any) => {
							if (r._id === this.state.currentRestaurant) {
								r.menu_api = this.state.currentMenuAPI;
								r.billing.base = base;
								r.billing.fee_type = this.state.currentFeeType;
								r.billing.fee_amount = fee_amount;
								r.billing.billing_id_stripe = this.state.currentStripeID;
								//
								r.billing.restaurant_billing_service_fees = this.state.currentServiceFees;
								r.billing.restaurant_billing_delivery_fees = this.state.currentDeliveryFees;
								//
								r.billing.restaurant_billing_pickup_tips = this.state.currentPickupTips;
								r.billing.restaurant_billing_delivery_tips = this.state.currentDeliveryTips;
								r.billing.restaurant_billing_dinein_tips = this.state.currentDineinTips;
							}
							return r;
						});
						return d;
					});
					const newAccounts = this.state.accounts.map((d: any) => {
						d.restaurants = d.restaurants.map((r: any) => {
							if (r._id === this.state.currentRestaurant) {
								r.menu_api = this.state.currentMenuAPI;
								r.billing.base = base;
								r.billing.fee_type = this.state.currentFeeType;
								r.billing.fee_amount = fee_amount;
								r.billing.billing_id_stripe = this.state.currentStripeID;
							}
							return r;
						});
						return d;
					});
					this.setState({
						data: newData,
						accounts: newAccounts,
						modal: false,
					});
					alert("The settings were updated successfully.");
				} else {
					console.log({ res });
					alert(
						"The settings were unable to be uploaded to the server. Please contact us."
					);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};



	handleInputChange = (event: { target: { value: any; }; }) => {
		this.setState({ searchKey: event.target.value });
	};

	handleReset = () => {
		this.setState({ searchKey: '' });
		this.loadRestaurants(10, 0, '')
	};

	render() {
		const masterEmails = ["support@cloudwaitress.com","test.reseller.au@cloudwaitress.com","test.reseller@cloudwaitress.com"];
		if (!this.state.accounts || this.state.accounts.length === 0) {
			return <RotateLoader size={3} />;
		}
		return (
			<div>
				<div className="mb-6">
					<h1 className="mb-4">Accounts</h1>
					<div className="flex">
						<p className="mr-4">
							Users:{" "}
							<span className="bg-gray-100 rounded px-2 py-1">
								{this.state.totalUsers}
							</span>
						</p>
						<p className="mr-4">
							Restaurants:{" "}
							<span className="bg-gray-100 rounded px-2 py-1">
								{this.state.totalRestaurants}
							</span>
						</p>
						<p>
							Billing:{" "}
							<span className="bg-gray-100 rounded px-2 py-1">
								{this.state.billingStatus}
							</span>
						</p>
					</div>
					<div className="flex w-full mt-4">
						<p>
							{this.state.billing_user ? (
								<Button
									className="-mt-1"
									onClick={this.toggleModalReseller}
									size="xxs"
									color="primary"
								>
									{this.state.email === "support@cloudwaitress.com"
										? "Cloudwaitress Settings"
										: "Billing Settings"}
								</Button>
							) : (
								<div></div>
							)}
						</p>
						<p>
							{this.state.billing_user ? (
								<Button
									className="ml-2 -mt-1"
									onClick={this.toggleModalTest}
									size="xxs"
									color="primary"
								>
									Upcoming Invoice Estimate
								</Button>
							) : (
								<span></span>
							)}
						</p>
						<p>
							{masterEmails.includes(this.state.email) ? (
								<Button
									className={`ml-2 -mt-1 ${this.state.masterFeatures ? "" : "hidden"
										}`}
									onClick={() => this.props.toggleMode("M")}
									size="xxs"
									color="primary"
								>
									Master Billings
								</Button>
							) : (
								<div></div>
							)}
						</p>
						<p>
							<Button
								className={`ml-2 -mt-1`}
								onClick={() => this.props.toggleMode("C")}
								size="xxs"
								color="primary"
							>
								Menu Copy
							</Button>
						</p>
						<p>
							{masterEmails.includes(this.state.email) && (
								<Button
									className={`ml-2 -mt-1`}
									onClick={() => this.props.toggleMode("T")}
									size="xxs"
									color="primary"
								>
									Transfer Restaurants
								</Button>
							)}
						</p>
					</div>
				</div>
				<form
					className="flex flex-no-wrap mb-6"
					onSubmit={(e) => {
						e.preventDefault();
						this.loadRestaurants(10, 0, this.state.searchKey)
					}}
				>
					<Input
						id="filter-input"
						placeholder="Filter by email or restaurant name"
						onChange={this.handleInputChange}
						value={this.state.searchKey}
					/>
					<Button color="primary" className="ml-4">
						Search
					</Button>
					<Button
						type="button" color="primary"
						className="ml-4"
						onClick={this.handleReset}
					>
						Reset
					</Button>
				</form>
				<p className="font-bold text-sm -mt-4 text-gray-900">
					Hint: A filter of "Active" will filter the active restaurants.
				</p>
				<p className="text-center text-md font-regular my-4 text-gray-900 underline">
					The order and revenue values are updated on the 30th minute, every
					hour, GMT.
				</p>
				<div className="flex w-full mt-4 justify-end">
					<Button
						className="ml-2 -mt-1"
						onClick={() => {
							if (this.skip > 0) {
								this.skip = Math.max(0, this.skip - 10);
								this.loadRestaurants(this.limit, this.skip, this.state.searchKey);
							}
						}}
						size="xxs"
						color="primary"
						disabled={this.skip <= 0}
					>
						Previous
					</Button>
					<Button className="ml-2 -mt-1"
						onClick={() => this.loadRestaurants(this.limit, this.skip += 10, this.state.searchKey)}
						size="xxs"
						color="primary"
						disabled={this.disabledNextButton}>
						Next
					</Button>
				</div>
				<br></br>

				<table className="table-auto w-full text-xs">
					<thead>
						<tr className="text-center">
							<th className="w-40">User</th>
							{this.state.resellerId === "cloudwaitress" && (
								<th className="">Reseller</th>
							)}
							{/* {this.state.resellerId === "cloudwaitress" && (
								<th className="">Stripe ID</th>
							)} */}
							<th className="">Restaurants</th>
							<th className="">Service Fees</th>
							<th className="">Delivery Fees</th>
							<th className="">Pickup Tips</th>
							<th className="">Delivery Tips</th>
							<th className="">Dine In Tips</th>
							<th>Currency</th>
							{/*<th>Fee per Order</th>*/}
							<th>Orders</th>
							<th>Revenue</th>
							{this.state.resellerId === "cloudwaitress" && (
								<th className="">Australia</th>
							)}
							{this.state.resellerId === "cloudwaitress" && (
								<th className="">Stripe</th>
							)}
							{this.state.resellerId === "cloudwaitress" && (
								<th className="">Stripe Connect</th>
							)}
							{this.state.resellerId === "cloudwaitress" && (
								<th className="">Apple Pay</th>
							)}
							{/*<th>Per Order Total</th>*/}
							{/*<th>Base Fee (USD)</th>*/}
							<th className="w-32">Actions</th>
							<th
								className={
									this.state.masterFeatures ||
										this.state.billing_original === "true"
										? ""
										: "hidden"
								}
							>
								Billing
							</th>
						</tr>
					</thead>
					<tbody className="align-top text-center align-middle">
						{this.state.data.map((d: any) => {
							return d.restaurants.map((r: any) => {
								return (
									<tr key={r._id}>
										<td className="border">{d.email}</td>
										{this.state.resellerId === "cloudwaitress" && (
											<td className="border">{d.reseller_id}</td>
										)}
										{/* {this.state.resellerId === "cloudwaitress" && (
											<td className="border">{r.billing.billing_id_stripe}</td>
										)} */}
										<td className="border">{r.name}</td>
										<td className="border">{r.service_fees}</td>
										<td className="border">{r.delivery_fees}</td>
										<td className="border">{r.tips_pickup}</td>
										<td className="border">{r.tips_delivery}</td>
										<td className="border">{r.tips_dine_in}</td>
										<td className="border">{r.currency}</td>
										{/*<td className="border">${r.fee_per_order}</td>*/}
										<td className="border">{r.orders}</td>
										<td className="border">${r.revenue}</td>
										{this.state.resellerId === "cloudwaitress" && (
											<td className="border">{r.australia}</td>
										)}
										{this.state.resellerId === "cloudwaitress" && (
											<td className="border">{r.stripe}</td>
										)}
										{this.state.resellerId === "cloudwaitress" && (
											<td className="border">{r.stripe_connect}</td>
										)}
										{this.state.resellerId === "cloudwaitress" && (
											<td className="border">{r.apple_pay}</td>
										)}

										{/*<td className="border">${r.per_order_total}</td>*/}
										{/*<td className="border">${r.base}</td>*/}
										<td className="border px-3 py-2">
											<Tooltip position="left" text="Login" width={90}>
												<Button
													size="xxs"
													type="button"
													className="!rounded-none"
													onClick={() => loginUser(d._id, false)}
												>
													L
												</Button>
											</Tooltip>
											<Tooltip
												position="right"
												text="Copy Login Link"
												width={90}
											>
												<Button
													size="xxs"
													type="button"
													className="!rounded-none"
													onClick={() => loginUser(d._id, true)}
												>
													CL
												</Button>
											</Tooltip>
										</td>
										<td
											className={
												this.state.masterFeatures ||
													this.state.billing_original === "true"
													? "border"
													: "hidden"
											}
										>
											<Button
												size="xxs"
												type="button"
												className="!rounded-none"
												onClick={(e) => {
													this.setState(
														{
															currentRestaurant: r._id,
															currentBaseOriginal: r.billing.base,
															currentMenuAPIOriginal: r.menu_api,
															currentFeeTypeOriginal: r.billing.fee_type,
															currentFeeAmountOriginal: r.billing.fee_amount,
															currentOrgID: r.organisation_id,
															currentStripeIDOriginal:
																r.billing.billing_id_stripe,
															//
															currentServiceFeesOriginal:
																r.billing.restaurant_billing_service_fees,
															currentDeliveryFeesOriginal:
																r.billing.restaurant_billing_delivery_fees,
															//
															currentPickupTipsOriginal:
																r.billing.restaurant_billing_pickup_tips,
															currentDeliveryTipsOriginal:
																r.billing.restaurant_billing_delivery_tips,
															currentDineinTipsOriginal:
																r.billing.restaurant_billing_dinein_tips,
															//
														},
														() => {
															this.toggleModal();
														}
													);
												}}
											>
												{this.state.masterFeatures
													? r.billing.base !== 0 || r.billing.fee_amount !== 0
														? "Active"
														: "Inactive"
													: "Billing"}
											</Button>
										</td>
									</tr>
								);
							});
						})}
					</tbody>
				</table>

				<div className="flex w-full mt-4 justify-end">
					<Button
						className="ml-2 -mt-1"
						onClick={() => {
							if (this.skip > 0) {
								this.skip = Math.max(0, this.skip - 10);
								this.loadRestaurants(this.limit, this.skip, this.state.searchKey);
							}
						}}
						size="xxs"
						color="primary"
						disabled={this.skip <= 0}
					>
						Previous
					</Button>
					<Button className="ml-2 -mt-1"
						onClick={() => this.loadRestaurants(this.limit, this.skip += 10, this.state.searchKey)}
						size="xxs"
						color="primary"
						disabled={this.disabledNextButton}>
						Next
					</Button>
				</div>




				<div
					className={`modal ${this.state.modal === true ? "" : "opacity-0 pointer-events-none"
						} fixed w-full h-full top-0 left-0 flex items-center justify-center`}
				>
					<div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
					<div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 h-full overflow-scroll">
						<div className="modal-content pt-6 pb-0 px-6">
							<div className="flex justify-center items-center pb-6">
								<p className="text-2xl font-bold poppins brand-foreground">
									Restaurant Billing Settings
								</p>
							</div>
							<div className="flex w-full justify-center">
								<div className="flex flex-col w-4/6">
									<div className="flex flex-col w-full">
										<div className="mb-2">
											<label className="block text-sm font-bold mb-2">
												Stripe Customer ID:
											</label>
											<input
												name="customer_id"
												type="text"
												value={this.state.currentStripeID}
												onChange={(e) =>
													this.setState({ currentStripeID: e.target.value })
												}
												className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
											/>
										</div>
										<div className="mb-2">
											<label className="block text-sm font-bold mb-2">
												Base Fee ({this.state.currency}):
											</label>
											<input
												name="base_fee"
												type="number"
												value={this.state.currentBase}
												onChange={(e) =>
													this.setState({ currentBase: e.target.value })
												}
												className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
											/>
										</div>
										<div className="mb-6">
											<label className="block text-sm font-bold mb-2">
												Fee Type:
											</label>
											<div className="inline-block relative w-full">
												<select
													name="currentFeeType"
													value={this.state.currentFeeType}
													onChange={(e) => {
														this.setState({ currentFeeType: e.target.value });
													}}
													className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
												>
													<option value="P">Percentage of revenue</option>
													<option value="A">Amount per order</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="mb-6">
											<label className="block text-sm font-bold mb-2">
												{this.state.currentFeeType === "P"
													? `Percentage of revenue (%):`
													: `Amount per order (${this.state.currency}):`}
											</label>
											<input
												name="currentFeeAmount"
												type="number"
												value={this.state.currentFeeAmount}
												onChange={(e) =>
													this.setState({ currentFeeAmount: e.target.value })
												}
												className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
											/>
										</div>
										{/* currentServiceFees: r.billing.restaurant_billing_service_fees,
										currentDeliveryFees: r.billing.restaurant_billing_delivery_fees,
										currentPickupTips: r.billing.restaurant_billing_pickup_tips,
										currentDeliveryTips: r.billing.restaurant_billing_delivery_tips,
										currentDineinTips: r.billing.restaurant_billing_dinein_tips, */}
										<div className="mb-6">
											<label className="block text-sm font-bold mb-2">
												Bill Service Fees?:
											</label>
											<div className="inline-block relative w-full">
												<select
													name="currentServiceFees"
													value={this.state.currentServiceFees}
													onChange={(e) => {
														this.setState({
															currentServiceFees: e.target.value,
														});
													}}
													className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
												>
													<option value="false">No</option>
													<option value="true">Yes</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="mb-6">
											<label className="block text-sm font-bold mb-2">
												Bill Delivery Fees?:
											</label>
											<div className="inline-block relative w-full">
												<select
													name="currentDeliveryFees"
													value={this.state.currentDeliveryFees}
													onChange={(e) => {
														this.setState({
															currentDeliveryFees: e.target.value,
														});
													}}
													className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
												>
													<option value="false">No</option>
													<option value="true">Yes</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="mb-6">
											<label className="block text-sm font-bold mb-2">
												Bill Pickup Tips?:
											</label>
											<div className="inline-block relative w-full">
												<select
													name="currentPickupTips"
													value={this.state.currentPickupTips}
													onChange={(e) => {
														this.setState({
															currentPickupTips: e.target.value,
														});
													}}
													className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
												>
													<option value="false">No</option>
													<option value="true">Yes</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="mb-6">
											<label className="block text-sm font-bold mb-2">
												Bill Delivery Tips?:
											</label>
											<div className="inline-block relative w-full">
												<select
													name="currentDeliveryTips"
													value={this.state.currentDeliveryTips}
													onChange={(e) => {
														this.setState({
															currentDeliveryTips: e.target.value,
														});
													}}
													className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
												>
													<option value="false">No</option>
													<option value="true">Yes</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="mb-6">
											<label className="block text-sm font-bold mb-2">
												Bill Dine-in Tips?:
											</label>
											<div className="inline-block relative w-full">
												<select
													name="currentDineinTips"
													value={this.state.currentDineinTips}
													onChange={(e) => {
														this.setState({
															currentDineinTips: e.target.value,
														});
													}}
													className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
												>
													<option value="false">No</option>
													<option value="true">Yes</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="mb-6">
											<label className="block text-sm font-bold mb-2">
												Menu API:
											</label>
											<div className="inline-block relative w-full">
												<select
													disabled={
														!this.state.menu_api &&
														this.state.email !== "support@cloudwaitress.com"
													}
													name="currentFeeType"
													value={this.state.currentMenuAPI}
													onChange={(e) => {
														this.setState({ currentMenuAPI: e.target.value });
													}}
													className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
												>
													<option value="false">Inactive</option>
													<option value="true">Active</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="flex items-center justify-center pb-4">
											<button
												className="text-white bg-red-500 rounded-lg font-semibold px-4 ml-2 py-2"
												onClick={this.toggleModal}
												onKeyDown={this.toggleModal}
											>
												Close
											</button>
											<button
												onClick={this.submitRestaurantSettings}
												onKeyDown={this.submitRestaurantSettings}
												className="text-white bg-green-500 rounded-lg font-semibold px-4 ml-2 py-2"
												disabled={
													this.state.currentBase ===
													this.state.currentBaseOriginal &&
													this.state.currentFeeType ===
													this.state.currentFeeTypeOriginal &&
													this.state.currentMenuAPI ===
													this.state.currentMenuAPIOriginal &&
													this.state.currentFeeAmount ===
													this.state.currentFeeAmountOriginal &&
													this.state.currentStripeID ===
													this.state.currentStripeIDOriginal &&
													this.state.currentServiceFees ===
													this.state.currentServiceFeesOriginal &&
													this.state.currentDeliveryFees ===
													this.state.currentDeliveryFeesOriginal &&
													this.state.currentPickupTips ===
													this.state.currentPickupTipsOriginal &&
													this.state.currentDeliveryTips ===
													this.state.currentDeliveryTipsOriginal &&
													this.state.currentDineinTips ===
													this.state.currentDineinTipsOriginal
												}
											>
												Submit
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`modal ${this.state.modalReseller === true
						? ""
						: "opacity-0 pointer-events-none"
						} fixed w-full h-full top-0 left-0 flex items-center justify-center`}
				>
					<div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
					<div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 h-full overflow-scroll">
						<div className="modal-content pt-6 pb-0 px-6">
							<div className="flex justify-center items-center pb-6">
								<p className="text-2xl font-bold poppins brand-foreground">
									Reseller Billing Settings
								</p>
							</div>
							<div className="flex flex-col w-full">
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Google Maps API Key (All restaurants):
									</label>
									<input
										name="flag"
										value={this.state.currentGoogleApiKey}
										onChange={(e) =>
											this.setState({ currentGoogleApiKey: e.target.value })
										}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-4">
									<label className="block text-sm font-bold mb-2">
										Reseller Billing:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="flag"
											value={this.state.billing}
											onChange={(e) => {
												this.setState({ billing: e.target.value });
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="false">Inactive</option>
											<option value="true">Active</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Billing Currency:
									</label>
									<input
										name="currency"
										type="string"
										value={this.state.currency}
										onChange={(e) =>
											this.setState({ currency: e.target.value })
										}
										disabled={this.state.billing === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Restaurant Default Base Fee ({this.state.currency}):
									</label>
									<input
										name="base_fee"
										type="number"
										value={this.state.base}
										onChange={(e) => this.setState({ base: e.target.value })}
										disabled={this.state.billing === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Restaurant Default Fee Type:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="currentFeeType"
											value={this.state.fee_type}
											disabled={this.state.billing === "false"}
											style={{
												backgroundColor:
													this.state.billing === "false"
														? "rgb(234,234,228)"
														: "white",
											}}
											onChange={(e) => {
												this.setState({ fee_type: e.target.value });
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="P">Percentage of revenue</option>
											<option value="A">Amount per order</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Restaurant Default Fee Amount ({this.state.currency}):
									</label>
									<input
										name="type_amount"
										type="number"
										value={this.state.fee_amount}
										onChange={(e) =>
											this.setState({ fee_amount: e.target.value })
										}
										disabled={this.state.billing === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Stripe Key (Secret Key):
									</label>
									<input
										name="flag"
										value={this.state.stripe}
										onChange={(e) => this.setState({ stripe: e.target.value })}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
										disabled={this.state.billing === "false"}
									/>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Bill Service Fees (New Restaurant Default)?:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="currentServiceFeesDefault"
											value={this.state.currentServiceFeesDefault}
											disabled={this.state.billing === "false"}
											style={{
												backgroundColor:
													this.state.billing === "false"
														? "rgb(234,234,228)"
														: "white",
											}}
											onChange={(e) => {
												this.setState({
													currentServiceFeesDefault: e.target.value,
												});
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="false">No</option>
											<option value="true">Yes</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Bill Delivery Fees (New Restaurant Default)?:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="currentDeliveryFeesDefault"
											value={this.state.currentDeliveryFeesDefault}
											disabled={this.state.billing === "false"}
											style={{
												backgroundColor:
													this.state.billing === "false"
														? "rgb(234,234,228)"
														: "white",
											}}
											onChange={(e) => {
												this.setState({
													currentDeliveryFeesDefault: e.target.value,
												});
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="false">No</option>
											<option value="true">Yes</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Bill Pickup Tips (New Restaurant Default)?:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="currentPickupTipsDefault"
											value={this.state.currentPickupTipsDefault}
											disabled={this.state.billing === "false"}
											style={{
												backgroundColor:
													this.state.billing === "false"
														? "rgb(234,234,228)"
														: "white",
											}}
											onChange={(e) => {
												this.setState({
													currentPickupTipsDefault: e.target.value,
												});
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="false">No</option>
											<option value="true">Yes</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Bill Delivery Tips (New Restaurant Default)?:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="currentDeliveryTipsDefault"
											value={this.state.currentDeliveryTipsDefault}
											disabled={this.state.billing === "false"}
											style={{
												backgroundColor:
													this.state.billing === "false"
														? "rgb(234,234,228)"
														: "white",
											}}
											onChange={(e) => {
												this.setState({
													currentDeliveryTipsDefault: e.target.value,
												});
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="false">No</option>
											<option value="true">Yes</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Bill Dine In Tips (New Restaurant Default)?:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="currentDineinTipsDefault"
											value={this.state.currentDineinTipsDefault}
											disabled={this.state.billing === "false"}
											style={{
												backgroundColor:
													this.state.billing === "false"
														? "rgb(234,234,228)"
														: "white",
											}}
											onChange={(e) => {
												this.setState({
													currentDineinTipsDefault: e.target.value,
												});
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="false">No</option>
											<option value="true">Yes</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Uber Delivery Margin (in percentage):
									</label>
									<input
										name="commissionFeeDefault"
										type="number"
										value={this.state.commissionFeeDefault}
										onChange={(e) =>
											this.setState({ commissionFeeDefault: e.target.value })
										}
										disabled={this.state.billing === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="flex items-center justify-center pb-4">
									<button
										className="text-white bg-red-500 rounded-lg font-semibold px-4 ml-2 py-2"
										onClick={this.toggleModalReseller}
										onKeyDown={this.toggleModalReseller}
									>
										Close
									</button>
									<button
										onClick={this.submitResellerSettings}
										onKeyDown={this.submitResellerSettings}
										className="text-white bg-green-500 rounded-lg font-semibold px-4 ml-2 py-2"
										disabled={
											this.state.base === this.state.base_original &&
											this.state.billing === this.state.billing_original &&
											this.state.stripe === this.state.stripe_original &&
											this.state.currency === this.state.currency_original &&
											this.state.fee_amount ===
											this.state.fee_amount_original &&
											this.state.fee_type === this.state.fee_type_original &&
											this.state.currentGoogleApiKey ===
											this.state.currentGoogleApiKeyOriginal &&
											this.state.currentServiceFeesDefault ===
											this.state.currentServiceFeesDefaultOriginal &&
											this.state.currentDeliveryFeesDefault ===
											this.state.currentDeliveryFeesDefaultOriginal &&
											this.state.currentPickupTipsDefault ===
											this.state.currentPickupTipsDefaultOriginal &&
											this.state.currentDeliveryTipsDefault ===
											this.state.currentDeliveryTipsDefaultOriginal &&
											this.state.currentDineinTipsDefault ===
											this.state.currentDineinTipsDefaultOriginal &&
											this.state.commissionFeeDefault ===
											this.state.commissionFeeDefaultOriginal

										}
									>
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`modal ${this.state.modalTest === true ? "" : "opacity-0 pointer-events-none"
						} fixed w-full h-screen top-0 left-0 flex items-center justify-center`}
				>
					<div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
					<div className="modal-container bg-white w-11/12 mx-auto rounded shadow-lg z-50 h-full overflow-scroll">
						<div className="modal-content pt-6 pb-0 px-6">
							<div className="flex justify-center items-center pb-6">
								<p className="text-2xl font-bold poppins brand-foreground">
									Upcoming Invoice Estimate ({this.state.currency})
								</p>
							</div>
							<div className="flex flex-col w-full justify-center">
								<div className="flex w-full justify-center">
									<p className={this.state.masterFeatures ? "" : "hidden"}>
										<Button
											className="ml-2 -mt-1"
											onClick={this.testResellerPayout}
											size="xxs"
											color="primary"
										>
											Test Reseller Payout
										</Button>
									</p>
								</div>
								<div className="flex w-full justify-center">
									<div className="flex w-56 flex-col justify-center">
										<div className="flex w-full flex-col justify-center">
											<div className="flex w-full justify-center mb-2">
												<label className="flex text-sm font-bold mb-2 w-full">
													Month:
												</label>
											</div>
											<div className="flex w-full justify-center mb-2">
												<div className="inline-block relative w-full justify-center">
													<select
														name="month"
														value={this.state.month}
														className="block bg-white appearance-none border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-56"
														onChange={(e) => {
															this.setState({
																month: e.target.value,
															});
														}}
													>
														<option value="January">January</option>
														<option value="February">February</option>
														<option value="March">March</option>
														<option value="April">April</option>
														<option value="May">May</option>
														<option value="June">June</option>
														<option value="July">July</option>
														<option value="August">August</option>
														<option value="September">September</option>
														<option value="October">October</option>
														<option value="November">November</option>
														<option value="December">December</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg
															className="fill-current h-4 w-4"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"
														>
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
											<label className="block text-sm font-bold mb-2">
												Year:
											</label>
											<div className="flex w-full justify-center mb-2">
												<div className="inline-block relative w-full justify-center">
													<select
														name="year"
														value={this.state.year}
														className="block bg-white appearance-none border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-56"
														onChange={(e) => {
															this.setState({
																year: e.target.value,
															});
														}}
													>
														<option value="2020">2020</option>
														<option value="2021">2021</option>
														<option value="2022">2022</option>
														<option value="2023">2023</option>
														<option value="2024">2024</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg
															className="fill-current h-4 w-4"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"
														>
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
										</div>
										<div className="flex w-full justify-center mt-2">
											<Button
												className="ml-2 -mt-1 p-2"
												onClick={this.getUpcomingInvoiceData}
												size="xs"
												color="primary"
											>
												Retrieve Data
											</Button>
										</div>
									</div>
								</div>
								<div className={this.state.testLoading ? "py-4" : ""}>
									{this.state.testLoading ? (
										<RotateLoader size={3} />
									) : (
										<div></div>
									)}
								</div>
								{this.state.invoiceLoading ? (
									<div className="py-10">
										<RotateLoader size={5} />
									</div>
								) : (
									<div></div>
								)}
								{this.state.invoiceLoading === false ? (
									<div className="flex w-full flex-col justify-center pt-4">
										{/* <div className="flex w-full items-center justify-center">
											<button
												className="text-white bg-red-500 rounded-lg font-semibold px-2 ml-2 py-2"
												onClick={this.toggleModalTest}
												onKeyDown={this.toggleModalTest}
											>
												Close
											</button>
										</div> */}
										<div className="flex w-full items-center justify-center mt-5">
											<p className="font-bold">
												Total: {this.state.upcoming_total.toFixed(2)} (
												{this.state.currency})
											</p>
										</div>
									</div>
								) : (
									<div></div>
								)}
								<table
									className={`mt-4 mb-6 table-auto w-full text-xs ${this.state.invoiceLoading ? "hidden" : ""
										}`}
								>
									<thead>
										<tr className="text-center">
											<th>Restaurant</th>
											<th>Reseller</th>
											<th>Email</th>
											<th>Orders</th>
											<th>Revenue</th>
											<th>Base</th>
											<th>Fee Type</th>
											<th>Fee</th>
											<th>Total Fees</th>
											<th>Total</th>
											<th
												className={
													this.state.masterFeatures || this.state.billing_user
														? ""
														: "hidden"
												}
											>
												Status
											</th>
										</tr>
									</thead>
									<tbody className="align-top text-center align-middle">
										{this.state.invoiceData.map((i: any) => {
											return (
												<tr key={i._id}>
													<td className="border">{i.name}</td>
													<td className="border">{i.reseller_id}</td>
													<td className="border">{i.email}</td>
													<td className="border">{i.orders}</td>
													<td className="border">{i.revenue}</td>
													<td className="border">{i.base}</td>
													<td className="border">
														{i.fee_type === "P"
															? "Revenue percentage"
															: "Amount per order"}
													</td>
													<td className="border">
														{i.fee}
														{i.fee_type === "P" ? "%" : ""}
													</td>
													<td className="border">{i.fees}</td>
													<td className="border">{i.base + i.fees}</td>
													<td
														className={
															this.state.masterFeatures ||
																this.state.billing_user
																? "border"
																: "hidden"
														}
													>
														{i.status}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
								<div className="flex w-full justify-center">
									{this.state.invoiceLoading === false &&
										this.state.invoiceData.length === 0 ? (
										<p className="text-lg font-bold pb-4">
											No data found for this time period
										</p>
									) : (
										<p></p>
									)}
								</div>
								<div className="flex items-center justify-center pb-4">
									<button
										className="text-white bg-red-500 rounded-lg font-semibold px-4 ml-2 py-2"
										onClick={this.toggleModalTest}
										onKeyDown={this.toggleModalTest}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export class MasterView extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props);
		const today = new Date();
		this.state = {
			email: this.props.email,
			testLoading: false,
			invoiceLoading: true,
			invoiceData: [],
			resellerModal: false,
			currentReseller: "",
			currentFlag: "",
			currentMenuAPI: false,
			currentBase: "",
			currentEmail: "",
			currentStripe: "",
			currentStripeID: "",
			currentCurrency: "",
			currentFeeType: "A",
			currentFeeAmount: 0,
			currentGoogleApiKey: "",
			currentGoogleApiKeyOriginal: "",
			currentFlagOriginal: "",
			currentMenuAPIOriginal: false,
			currentEmailOriginal: "",
			currentStripeOriginal: "",
			currentStripeIDOriginal: "",
			currentBaseOriginal: "",
			currentCurrencyOriginal: "",
			currentFeeTypeOriginal: "A",
			currentFeeAmountOriginal: 0,
			year: today.getFullYear().toString(),
			month: NumberToMonth(today.getMonth()),
		};
	}
	componentDidMount = () => {
		api
			.getMasterData({
				period: this.state.month + "-" + this.state.year,
			})
			.then((res: any) => {
				// console.log({ res })
				this.setState({
					invoiceData: res,
					invoiceLoading: false,
				});
			});
	};
	getUpcomingInvoiceData = () => {
		this.setState(
			{
				invoiceLoading: true,
			},
			() => {
				api
					.getMasterData({
						period: this.state.month + "-" + this.state.year,
					})
					.then((res: any) => {
						// console.log({ res })
						this.setState({
							invoiceData: res,
							invoiceLoading: false,
						});
					});
			}
		);
	};
	testResellerPayout = () => {
		this.setState(
			{
				testLoading: true,
			},
			async () => {
				// await this.state.invoiceData.map(async (d: any) => {
				const currentData = this.state.invoiceData;
				for (let d of currentData) {
					// await sleep(1000);
					await api
						.postResellerInvoiceData({
							mode: "test",
							// mode: "live",
							year: this.state.year,
							month: this.state.month,
							record: d,
						})
						.then((res) => {
							let oldData = this.state.invoiceData;
							let newData = oldData.map((data: any) => {
								if (d._id === data._id) {
									data.status = res[0].status;
								}
								return data;
							});
							this.setState({
								invoiceData: newData,
							});
							// return d
						})
						.catch((error) => {
							alert(error);
						});
				}
				this.setState({
					testLoading: false,
				});
			}
		);
	};
	submitResellerBillingSettings = async () => {
		const customerIDCheckResult = customerIDCheck(this.state.currentStripeID);
		if (
			customerIDCheckResult === false &&
			this.state.currentMenuAPI === this.state.currentMenuAPIOriginal
		) {
			return;
		}
		let base = 0;
		if (this.state.currentBase !== null && this.state.currentBase !== "") {
			base = parseFloat(this.state.currentBase);
		}
		let fee_amount = 0;
		if (
			this.state.currentFeeAmount !== null &&
			this.state.currentFeeAmount !== ""
		) {
			fee_amount = parseFloat(this.state.currentFeeAmount);
		}
		if (
			base === this.state.currentBaseOriginal &&
			fee_amount === this.state.currentFeeAmountOriginal &&
			this.state.currentFeeType === this.state.currentFeeTypeOriginal &&
			this.state.currentFlag === this.state.currentFlagOriginal &&
			this.state.currentMenuAPI === this.state.currentMenuAPIOriginal &&
			this.state.currentMenuAPI === this.state.currentMenuAPIOriginal &&
			this.state.currentCurrency === this.state.currentCurrencyOriginal &&
			this.state.currentEmail === this.state.currentEmailOriginal &&
			this.state.currentStripe === this.state.currentStripeOriginal &&
			this.state.currentStripeID === this.state.currentStripeIDOriginal &&
			this.state.currentGoogleApiKey === this.state.currentGoogleApiKeyOriginal
		) {
			// If the user just removed numbers, dont post, but cleanup the data if needed
			const newData = this.state.invoiceData.map((d: any) => {
				if (d._id === this.state.currentReseller) {
					d.base = base;
					d.fee_amount = fee_amount;
				}
				return d;
			});
			this.setState({
				invoiceData: newData,
			});
			return;
		}
		// console.log(this.state.currentMenuAPI)
		const resellerData = {
			_id: this.state.currentReseller,
			flag: this.state.currentFlag,
			menu_api: this.state.currentMenuAPI,
			currency: this.state.currentCurrency,
			base: base,
			fee_type: this.state.currentFeeType,
			fee_amount: fee_amount,
			email: this.state.currentEmail,
			stripe: this.state.currentStripe,
			stripe_id: this.state.currentStripeID,
			currentGoogleApiKey: this.state.currentGoogleApiKey,
		};
		api
			.updateResellerBilling(resellerData)
			.then((res) => {
				if (res.message === "OK") {
					const newData = this.state.invoiceData.map((d: any) => {
						if (d._id === this.state.currentReseller) {
							d.base = base;
							d.fee_type = this.state.currentFeeType;
							d.fee = fee_amount;
							d.currency = this.state.currentCurrency;
							d.flag = this.state.currentFlag;
							d.menu_api = this.state.currentMenuAPI;
							d.email = this.state.currentEmail;
							d.stripe = this.state.currentStripe;
							d.customer_id = this.state.currentStripeID;
							d.currentGoogleApiKey = this.state.currentGoogleApiKey;
							if (d.fee_type === "A") {
								d.fees = d.orders * fee_amount;
								d.fees = parseFloat(d.fees.toFixed(2));
							} else if (d.fee_type === "P") {
								d.fees = d.revenue * (fee_amount / 100);
								d.fees = parseFloat(d.fees.toFixed(2));
							}
						}
						return d;
					});
					this.setState({
						invoiceData: newData,
						resellerModal: false,
					});
					alert("The settings were updated successfully.");
				} else {
					console.log({ res });
					alert(
						"The settings were unable to be uploaded to the server. Please contact us."
					);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};
	toggleResellerModal = () => {
		if (!this.state.resellerModal) {
			const body = document.querySelector("body");
			// @ts-ignore
			body.classList.add("modal-active");
		} else {
			const body = document.querySelector("body");
			// @ts-ignore
			body.classList.remove("modal-active");
		}
		this.setState({
			resellerModal: !this.state.resellerModal,
			currentFlag: this.state.currentFlagOriginal,
			currentBase: this.state.currentBaseOriginal,
			currentCurrency: this.state.currentCurrencyOriginal,
			currentMenuAPI: this.state.currentMenuAPIOriginal,
			currentFeeType: this.state.currentFeeTypeOriginal,
			currentFeeAmount: this.state.currentFeeAmountOriginal,
			currentStripe: this.state.currentStripeOriginal,
			currentStripeID: this.state.currentStripeIDOriginal,
			currentEmail: this.state.currentEmailOriginal,
			currentGoogleApiKey: this.state.currentGoogleApiKeyOriginal,
		});
	};
	render() {
		return (
			<div>
				<div className="mb-6">
					<h1 className="mb-4">Master Billings</h1>
				</div>
				<div className="flex flex-col w-full justify-center">
					<div className="flex w-full justify-center">
						<p>
							<Button
								className="ml-2 -mt-1 mb-4"
								onClick={() => this.props.toggleMode("N")}
								size="xxs"
								color="primary"
							>
								Back to Dashboard
							</Button>
						</p>
						<p>
							{this.state.email === "support@cloudwaitress.com" ? (
								<Button
									className="ml-2 -mt-1"
									onClick={this.testResellerPayout}
									size="xxs"
									color="primary"
								>
									Test Reseller Payout
								</Button>
							) : (
								<span></span>
							)}
						</p>
					</div>
					<div className="flex w-full justify-center">
						<div className="flex w-56 flex-col justify-center">
							<div className="flex w-full flex-col justify-center">
								<div className="flex w-full justify-center mb-2">
									<label className="flex text-sm font-bold mb-2 w-full">
										Month:
									</label>
								</div>
								<div className="flex w-full justify-center mb-2">
									<div className="inline-block relative w-full justify-center">
										<select
											name="month"
											value={this.state.month}
											className="block bg-white appearance-none border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-56"
											onChange={(e) => {
												this.setState({
													month: e.target.value,
												});
											}}
										>
											<option value="January">January</option>
											<option value="February">February</option>
											<option value="March">March</option>
											<option value="April">April</option>
											<option value="May">May</option>
											<option value="June">June</option>
											<option value="July">July</option>
											<option value="August">August</option>
											<option value="September">September</option>
											<option value="October">October</option>
											<option value="November">November</option>
											<option value="December">December</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<label className="block text-sm font-bold mb-2">Year:</label>
								<div className="flex w-full justify-center mb-2">
									<div className="inline-block relative w-full justify-center">
										<select
											name="year"
											value={this.state.year}
											className="block bg-white appearance-none border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-56"
											onChange={(e) => {
												this.setState({
													year: e.target.value,
												});
											}}
										>
											<option value="2020">2020</option>
											<option value="2021">2021</option>
											<option value="2022">2022</option>
											<option value="2023">2023</option>
											<option value="2024">2024</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
							</div>
							<div className="flex w-full justify-center mt-2">
								<Button
									className="ml-2 -mt-1 p-2"
									onClick={this.getUpcomingInvoiceData}
									size="xs"
									color="primary"
								>
									Retrieve Data
								</Button>
							</div>
						</div>
					</div>
					<div className={this.state.testLoading ? "py-4" : ""}>
						{this.state.testLoading ? <RotateLoader size={3} /> : <div></div>}
					</div>
					{this.state.invoiceLoading ? (
						<div className="py-10">
							<RotateLoader size={5} />
						</div>
					) : (
						<div></div>
					)}
					<table
						className={`mt-4 mb-6 table-auto w-full text-xs ${this.state.invoiceLoading ? "hidden" : ""
							}`}
					>
						<thead>
							<tr className="text-center">
								<th>Reseller</th>
								<th>Restaurants</th>
								<th>Orders</th>
								<th>Revenue</th>
								<th>Reseller Revenue</th>
								<th>Base</th>
								<th>Total Base</th>
								<th>Fee Type</th>
								<th>Fee</th>
								<th>Total Fees</th>
								<th>Currency</th>
								<th>Total</th>
								<th>Google Maps Key</th>
								<th>Status</th>
								<th>Stripe</th>
							</tr>
						</thead>
						<tbody className="align-top text-center align-middle">
							{this.state.invoiceData.map((i: any) => {
								return (
									<tr key={i._id}>
										<td className="border">{i.name}</td>
										<td className="border">{i.restaurant_count}</td>
										<td className="border">{i.orders}</td>
										<td className="border">{i.revenue}</td>
										<td className="border">{i.reseller_fees}</td>
										<td className="border">{i.base}</td>
										<td className="border">{i.total_base}</td>
										<td className="border">
											{i.fee_type === "P"
												? "Revenue percentage"
												: "Amount per order"}
										</td>
										<td className="border">
											{i.fee}
											{i.fee_type === "P" ? "%" : ""}
										</td>
										<td className="border">{i.fees}</td>
										<td className="border">{i.currency}</td>
										<td className="border">{i.total}</td>
										<td className="border">
											{i.google_api && i.google_api.length > 0 ? "Y" : "N"}
										</td>
										<td className="border">
											<Button
												size="xxs"
												type="button"
												className="!rounded-none"
												onClick={(e) => {
													this.setState(
														{
															currentReseller: i._id,
															currentFlagOriginal: i.flag,
															currentBaseOriginal: i.base,
															currentCurrencyOriginal: i.currency,
															currentFeeTypeOriginal: i.fee_type,
															currentFeeAmountOriginal: i.fee,
															currentEmailOriginal: i.email,
															currentStripeOriginal: i.stripe,
															currentStripeIDOriginal: i.customer_id,
															currentMenuAPIOriginal: i.menu_api,
															currentGoogleApiKeyOriginal: i.google_api,
														},
														() => {
															this.toggleResellerModal();
														}
													);
												}}
											>
												{i.flag === "true" ? "Active" : "Inactive"}
											</Button>
										</td>
										<td className="border">{i.status}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="flex w-full justify-center">
						{this.state.invoiceLoading === false &&
							this.state.invoiceData.length === 0 ? (
							<p className="text-lg font-bold pb-4">
								No data found for this time period
							</p>
						) : (
							<p></p>
						)}
					</div>
				</div>
				<div
					className={`modal ${this.state.resellerModal === true
						? ""
						: "opacity-0 pointer-events-none"
						} fixed w-full h-full top-0 left-0 flex items-center justify-center`}
				>
					<div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
					<div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
						<div className="modal-content pt-6 pb-0 px-6">
							<div className="flex justify-center items-center pb-6">
								<p className="text-2xl font-bold poppins brand-foreground">
									Reseller Billing Settings
								</p>
							</div>
							<div className="flex flex-col w-full">
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Google Maps API Key (All Restaurants for this reseller)
									</label>
									<input
										name="google_api"
										type="string"
										value={this.state.currentGoogleApiKey}
										onChange={(e) =>
											this.setState({ currentGoogleApiKey: e.target.value })
										}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-4">
									<label className="block text-sm font-bold mb-2">
										Reseller Billing:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="flag"
											value={this.state.currentFlag}
											onChange={(e) => {
												this.setState({ currentFlag: e.target.value });
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="false">Inactive</option>
											<option value="true">Active</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Reseller Stripe ID:
									</label>
									<input
										name="stripe_id"
										type="string"
										value={this.state.currentStripeID}
										onChange={(e) =>
											this.setState({ currentStripeID: e.target.value })
										}
										disabled={this.state.currentFlag === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Billing Currency:
									</label>
									<input
										name="currency"
										type="string"
										value={this.state.currentCurrency}
										onChange={(e) =>
											this.setState({ currentCurrency: e.target.value })
										}
										disabled={this.state.currentFlag === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Our Stripe Key:
									</label>
									<input
										name="stripe"
										type="string"
										value={this.state.currentStripe}
										onChange={(e) =>
											this.setState({ currentStripe: e.target.value })
										}
										disabled={this.state.currentFlag === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Billing Email:
									</label>
									<input
										name="email"
										type="string"
										value={this.state.currentEmail}
										onChange={(e) =>
											this.setState({ currentEmail: e.target.value })
										}
										disabled={this.state.currentFlag === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-2">
									<label className="block text-sm font-bold mb-2">
										Restaurant Default Base Fee ({this.state.currentCurrency}):
									</label>
									<input
										name="base_fee"
										type="number"
										value={this.state.currentBase}
										onChange={(e) =>
											this.setState({ currentBase: e.target.value })
										}
										disabled={this.state.currentFlag === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										Fee Type:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="currentFeeType"
											value={this.state.currentFeeType}
											onChange={(e) => {
												this.setState({ currentFeeType: e.target.value });
											}}
											disabled={this.state.currentFlag === "false"}
											style={{
												backgroundColor:
													this.state.currentFlag === "false"
														? "rgb(234,234,228)"
														: "white",
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="P">Percentage of revenue</option>
											<option value="A">Amount per order</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="mb-6">
									<label className="block text-sm font-bold mb-2">
										{this.state.currentFeeType === "P"
											? `Percentage of revenue (%):`
											: `Amount per order (${this.state.currentCurrency}):`}
									</label>
									<input
										name="currentFeeAmount"
										type="number"
										value={this.state.currentFeeAmount}
										onChange={(e) =>
											this.setState({ currentFeeAmount: e.target.value })
										}
										disabled={this.state.currentFlag === "false"}
										className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
									/>
								</div>
								<div className="mb-4">
									<label className="block text-sm font-bold mb-2">
										Menu API:
									</label>
									<div className="inline-block relative w-full">
										<select
											name="flag"
											value={this.state.currentMenuAPI}
											onChange={(e) => {
												this.setState({ currentMenuAPI: e.target.value });
											}}
											className="block bg-white appearance-none w-full border border-gray-400 py-3 px-4 pr-8 rounded leading-tight w-full"
										>
											<option value="false">Inactive</option>
											<option value="true">Active</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center pb-4">
									<button
										className="text-white bg-red-500 rounded-lg font-semibold px-4 ml-2 py-2"
										onClick={this.toggleResellerModal}
										onKeyDown={this.toggleResellerModal}
									>
										Close
									</button>
									<button
										onClick={this.submitResellerBillingSettings}
										onKeyDown={this.submitResellerBillingSettings}
										className="text-white bg-green-500 rounded-lg font-semibold px-4 ml-2 py-2"
										disabled={
											this.state.currentBase ===
											this.state.currentBaseOriginal &&
											this.state.currentFeeAmount ===
											this.state.currentFeeAmountOriginal &&
											this.state.currentFeeType ===
											this.state.currentFeeTypeOriginal &&
											this.state.currentFlag ===
											this.state.currentFlagOriginal &&
											this.state.currentMenuAPI ===
											this.state.currentMenuAPIOriginal &&
											this.state.currentEmail ===
											this.state.currentEmailOriginal &&
											this.state.currentStripe ===
											this.state.currentStripeOriginal &&
											this.state.currentStripeID ===
											this.state.currentStripeIDOriginal &&
											this.state.currentCurrency ===
											this.state.currentCurrencyOriginal &&
											this.state.currentGoogleApiKey ===
											this.state.currentGoogleApiKeyOriginal
										}
									>
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const StyledInput = styled.input`
	height: 35px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0 10px;
	font-size: 14px;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 10px;
`;

const StyledButton = styled.button`
	height: 35px;
	background-color: #6558f5;
	color: #ffffff;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 14px;
	border: none;
	cursor: pointer;
	margin-left: 3px;
`;

const StyledButton2 = styled.button`
	height: 35px;
	background-color: #6558f5;
	color: #ffffff;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 14px;
	border: none;
	cursor: pointer;
	margin-left: 3px;
	margin-top:20px;
`;

const SearchButton = styled(StyledButton)`
	background-color: #6558f5;
`;

const ResetButton = styled(StyledButton)`
	background-color: #ee5252;
`;

const BackButton = styled(StyledButton)`
margin-bottom: 30px;
	background-color: #6558f5;	
`;
export class MenuCopyView extends React.Component<Props, any> {
	constructor(props: Readonly<Props>) {
		super(props);
		this.state = {
			restaurants: [],
			fromRestaurants: [],
			toRestaurants: [],
			searchTextFrom: "",
			searchTextTo: "",
			fromRestaurantsList: [],
			toRestaurantsList: [],
			loadingFrom: false,
			loadingTo: false,
			defaultRestaurantSearchKey:"limit=5&skip0"
		};
	}

	componentDidMount() {
		this.fetchRestaurants();
	}

	fetchRestaurants = async () => {
		this.setState({ loadingFrom: true, loadingTo: true,});
		try {
			const response = await api.getBaseRestaurantData({
				email: this.state.email,
			}, this.state.defaultRestaurantSearchKey)
			this.setState({
				restaurants: response.restaurants,
				fromRestaurantsList: response.restaurants,
				toRestaurantsList: response.restaurants,
				loadingFrom: false,
				loadingTo: false
			});
		} catch (error) {
			console.error("Failed to fetch restaurants", error);
			this.setState({ loadingFrom: false });
			this.setState({ loadingTo: false });
		}
	};

	handleSearchFrom = async () => {
		this.setState({ loadingFrom: true});
		try {
			var key = this.state.defaultRestaurantSearchKey
			if (this.state.searchTextFrom != ""){
				key = "name=" + this.state.searchTextFrom
			}
			const response = await api.getBaseRestaurantData({
				email: this.state.email,
			}, key)
			this.setState({ fromRestaurantsList: response.restaurants, loadingFrom: false});
		} catch (error) {
			console.error("Failed to search restaurants", error);
			this.setState({ loadingFrom: false});
		}
	};

	handleSearchTo = async () => {
		this.setState({ loadingTo: true });
		try {
			var key = this.state.defaultRestaurantSearchKey
			if (this.state.searchTextTo != ""){
				key = "name=" + this.state.searchTextTo
			}
			const response = await api.getBaseRestaurantData({
				email: this.state.email,
			}, key)
			this.setState({ toRestaurantsList: response.restaurants, loadingTo: false });
		} catch (error) {
			console.error("Failed to search restaurants", error);
			this.setState({ loadingTo: false });
		}
	};

	handleResetFrom = () => {
		this.setState({ searchTextFrom: "", fromRestaurantsList: this.state.restaurants });
	};

	handleResetTo = () => {
		this.setState({ searchTextTo: "", toRestaurantsList: this.state.restaurants });
	};

	handleCheckboxChange = (restaurantId: any, listName: string) => {
		const selectedList = this.state[listName];
		if (selectedList.includes(restaurantId)) {
			this.setState({ [listName]: selectedList.filter((id: any) => id !== restaurantId) });
		} else {
			this.setState({ [listName]: [...selectedList, restaurantId] });
		}
	};

	handleSelectAll = (listName: string) => {
		this.setState({ [listName]: this.state[listName + "List"].map((rest: { _id: any; }) => rest._id) });
	};

	handleDeselectAll = (listName: any) => {
		this.setState({ [listName]: [] });
	};

	submit = () => {
		const copyMenuData = {
			fromRestaurant: this.state.fromRestaurants[0],
			toRestaurants: this.state.toRestaurants,
			// menus: this.state.menus
		};
		this.setState(
			{
				loadingFrom:true,
				loadingTo: true,
			},
			() => {
				api
					.postMenuCopyData(copyMenuData)
					.then((res) => {
						if (true) {
							this.setState(
								{
									loadingFrom:false,
									loadingTo: false,
								},
								() => {
									alert("The menus have been copied");
								}
							);
						} else {
							this.setState(
								{
									loadingFrom:false,
									loadingTo: false,
								},
								() => {
									alert("Something went wrong. Please contat support.");
								}
							);
						}
					})
					.catch((err) => {
						console.log({ err });
					});
			}
		);
	};


	renderTable = (listName: string, listData: any[]) => {
		return (
			<table className="table-auto w-full">
				<thead>
					<tr>
						<th className="px-4 py-2">
							<input
								type="checkbox"
								checked={this.state[listName].length === listData.length}
								onChange={() => {
									if (this.state[listName].length === listData.length) {
										this.handleDeselectAll(listName);
									} else {
										this.handleSelectAll(listName);
									}
								}}
							/>
						</th>
						<th className="px-4 py-2">Restaurant Name</th>
					</tr>
				</thead>
				<tbody>
					{listData.map((restaurant: any) => (
						<tr key={restaurant._id}>
							<td className="border px-4 py-2">
								<input
									type="checkbox"
									checked={this.state[listName].includes(restaurant._id)}
									onChange={() => this.handleCheckboxChange(restaurant._id, listName)}
								/>
							</td>
							<td className="border px-4 py-2">{restaurant.name}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	render() {
		return (
			<div>
				<div className="mb-6">
					<h1 className="mb-4">Menu Copy</h1>
				</div>
				<div className="flex flex-col w-full justify-center">
					<div className="flex w-full justify-center">
						<p>
							<BackButton onClick={() => this.props.toggleMode("N")}>
								Back to Dashboard
							</BackButton>
						</p>
					</div>
					<div className={this.state.loadingTo ? "py-4" : ""}>
						{this.state.loadingFrom ? (
							<RotateLoader size={3} />
						) : (
							<div></div>
						)}
					</div>
					<div className={`flex w-full justify-center : ""}`}>
						<div className="flex w-90 flex-col justify-center">
							<div className="flex w-full flex-col justify-center">
								<div className="flex w-full justify-center mb-2">
									<label className="flex text-sm font-bold w-full">From Restaurant:</label>
								</div>
								<div className="flex w-full justify-center mb-2">
									<StyledInput
										type="text"
										placeholder="Search From Restaurant"
										value={this.state.searchTextFrom}
										onChange={(e) => this.setState({ searchTextFrom: e.target.value })}
									/>
									<SearchButton onClick={this.handleSearchFrom}>Search</SearchButton>
									<ResetButton onClick={this.handleResetFrom}>Reset</ResetButton>
								</div>
								{this.renderTable("fromRestaurants", this.state.fromRestaurantsList)}
								<div className="flex w-full justify-left mt-3 mb-2">
									<label className="block text-sm font-bold">Restaurants to copy to:</label>
								</div>
								<div className="flex w-full justify-center mb-2">
									<StyledInput
										type="text"
										placeholder="Search Restaurants to Copy To"
										value={this.state.searchTextTo}
										onChange={(e) => this.setState({ searchTextTo: e.target.value })}
									/>
									<SearchButton onClick={this.handleSearchTo}>Search</SearchButton>
									<ResetButton onClick={this.handleResetTo}>Reset</ResetButton>
								</div>
								{this.renderTable("toRestaurants", this.state.toRestaurantsList)}
							</div>
						</div>
					</div>
					<div className="flex w-full justify-center">
						<p>
							<StyledButton2
								onClick={this.submit}
								disabled={this.state.fromRestaurants.length === 0 || this.state.toRestaurants.length === 0}
							>
								Copy Menus
							</StyledButton2>
						</p>
					</div>
				</div>
			</div>
		);
	}
}






function NumberToMonth(inputNumber: number): string {
	//
	var month = "";
	//
	if (inputNumber === 0) {
		month = "January";
	} else if (inputNumber === 1) {
		month = "February";
	} else if (inputNumber === 2) {
		month = "March";
	} else if (inputNumber === 3) {
		month = "April";
	} else if (inputNumber === 4) {
		month = "May";
	} else if (inputNumber === 5) {
		month = "June";
	} else if (inputNumber === 6) {
		month = "July";
	} else if (inputNumber === 7) {
		month = "August";
	} else if (inputNumber === 8) {
		month = "September";
	} else if (inputNumber === 9) {
		month = "October";
	} else if (inputNumber === 10) {
		month = "November";
	} else if (inputNumber === 11) {
		month = "December";
	}
	//
	return month;
}
