import React from "react";
import { Button, Input } from "@lib/components";
import { api } from "../api";
import { RotateLoader, Consent } from "@lib/components";

interface Props {
	setLoggedIn: (v: boolean) => void;
	masterEmail: (v: string) => void;
}

export function ViewLogin({ setLoggedIn, masterEmail }: Props) {
	const [loading, setLoading] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	return (
		<>
			<form
				className="flex flex-no-wrap"
				onSubmit={async (e) => {
					e.preventDefault();
					try {
						setLoading(true);
						const result = await api.login({ email, password });
						if (result.outcome) {
							alert(result.message);
						} else {
							api.token = result.token;
							masterEmail(email);
							setLoggedIn(true);
						}
					} catch (e) {
						console.log(e);
						alert("Something went wrong, please try again or contact us");
					} finally {
						setLoading(false);
					}
				}}
			>
				<Input
					type="email"
					name="email"
					value={email}
					placeholder="E-Mail"
					onChange={(e) => setEmail(e.target.value)}
				/>
				<Input
					type="password"
					name="password"
					value={password}
					placeholder="Password"
					onChange={(e) => setPassword(e.target.value)}
					className="ml-4"
				/>

				<Button color="primary" className="ml-4">
					{loading ? <RotateLoader size={2} /> : "Submit"}
				</Button>
			</form>
		<Consent privacyLink="/privacypolicy" />
		</>
	);
}
