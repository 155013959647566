/// <reference types="@lib/types" />
import "react-hot-loader";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./app";

function renderApp() {
	const root = document.getElementById("root");
	ReactDOM.render(<App />, root);
}

window.onload = renderApp;

// @ts-ignore
if (module && module.hot) {
	// @ts-ignore
	module.hot.accept(renderApp);
}
