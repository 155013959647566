import { darken, rgb } from "polished";
import { CoreUtils } from "@lib/common";

export const theme: T.DefaultTheme = {

	font: {
		header: "Lato,Verdana,Helvetica,sans-serif;",
		body: "Lato,Verdana,Helvetica,sans-serif;",
		size: 14,
	},

	colors: {
		primary: "rgb(238,82,82)",
		primary_text: "white",
		background: "#333",
		text: "#383838",
		success: "#51a351",
		warning: "#f89406",
		error: "#bd362f",
	},

	status_colors: {
		complete: "#51A351",
		onroute: "#00BFFF",
		ready: "#0080FF",
		confirmed: "#B7D024",
		unconfirmed: "#efbb1e",
		cancelled: "#BD362F",
	},

	button: {
		height: 38,
	},

	box: {
		background: "white",
		text: "#212121",
		border: darken(0.16, "white"),
	},

	input: {
		background: "white",
		text: "#212121",
		border: darken(0.16, "white"),
		height: 38,
		fontSize: 14,
	},

	top_nav: {
		zindex: 10,
		height: 58,
		background_color: "white",
		text_color: "#383838",
	},

	side_nav: {
		zindex: 9,
		width: 70,
		background_color: "white",
		text_color: "#383838",
	},

	slide_panel: {
		zindex: 9,
		background_color: "white",
		text_color: "#383838",
	},

	footer: {
		background: "white",
		text: "#212121",
	},

	content_width: {
		xs: 360,
		sm: 480,
		md: 720,
		lg: 1140,
		xl: 1360,
	},

	breakpoints: CoreUtils.ui.breakpoints,

};