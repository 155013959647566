import { hot } from "react-hot-loader/root";
import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { ViewAccounts } from "./views/accounts";
import { ViewLogin } from "./views/login";
import { Policy } from "./views/policy";

function App() {
	const [isLoggedIn, setLoggedIn] = React.useState(false);
	const [email, setEmail] = React.useState("");
	return (
		<ThemeProvider theme={theme}>
			<main id="scroll-root" className="mx-auto mt-10 px-4">
				<div className="bg-white px-8 py-10">

					{ location.pathname === '/privacypolicy' ?
					  <Policy />
					:
						<> 
							{!isLoggedIn && (
								<ViewLogin setLoggedIn={setLoggedIn} masterEmail={setEmail} />
							)}
							{isLoggedIn && <ViewAccounts email={email} /> }
						</>
					
					}
				</div>
			</main>
		</ThemeProvider>
	);
}

export default hot(App);
